import Util from "app/PreLogin/Login/LoginUtil";
import {User} from "shared/interfaces";
import localStorage from "react/utils/local_storage";

// centralize custom skinning


export default class ThemeUtils {
  static justParkColor = '#1c9531';
  static isJustPark = window.location.hostname.includes('justpark');
  static getOrganizationFromUser = (user: User | null): string | null => {
    const organization = (user && user.organization) || "NONE";
    if (Array.from(organization)[0] === "#") {
      return organization.slice(1);
    }
    return null;
  };
  static themeMap: { [key: string]: string } = {
    "default": "Default",
    "justpark": "JustPark",
    "smarking": "Smarking (new theme)",
    "parking-eye": "Parking Eye",
    "nsl": "NSL",
    "smart-parking": "Smart Parking",
    "creative-car-parks": "Creative Car Parks",
    "ncp": "NCP",
    "peak-parking": "Peak Parking",
  }
  static localStorageThemeKey = 'smk-theme';
  static getThemeFromUser = (user: User | null | undefined): string | null => {
    const theme = user && user.theme;
    if (theme && theme != "default") {
      return theme;
    }
    return null;
  };

  static getOrganizationPrefixFromDomain = (): string => {
    const hostname = window.location.hostname;
    if (window.location.pathname.includes('internal')) {
      return '';
    } else if (hostname.includes('parkhub')) {
      return 'parkhub';
    } else if (hostname.includes('justpark')) {
      return 'justpark';
    }
    return '';
  };
  static getCustomStyleClassForDomain = (classString: string, returnOnlyCustom: boolean = false): string => {
    const organizationPrefix = this.getOrganizationPrefixFromDomain();
    if (organizationPrefix.length) {
      const customClass = `${organizationPrefix}-${classString}`;
      if (returnOnlyCustom) {
        return customClass;
      }
      return `${classString} ${customClass}`;
    } else {
      return classString;
    }
  }
  static getThemeFromLocalStorage = (): string | null => {
    return localStorage.getItem(this.localStorageThemeKey);
  }

  static setThemeToLocalStorage = (theme: string) => {
    localStorage.setItem(this.localStorageThemeKey, theme);
  }
  static resetLocalTheme = () => {
    localStorage.removeItem(this.localStorageThemeKey);
  }

  static getCurrentTheme = (user: User | null | undefined) => {
    return this.getThemeFromLocalStorage() ||
      this.getThemeFromUser(user) ||
      this.getOrganizationFromUser(user) ||
      this.getOrganizationPrefixFromDomain();
  }

  static getCustomStyleClassForUserOrDomain = (user: User | null | undefined, classString: string, returnOnlyCustom: boolean = false): string => {

    const organizationPrefix = this.getCurrentTheme(user);
    if (organizationPrefix.length) {
      const customClass = `${organizationPrefix}-${classString}`;
      if (returnOnlyCustom) {
        return customClass;
      }
      return `${classString} ${customClass}`;
    } else {
      return classString;
    }
  }
}